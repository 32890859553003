import { css } from '@emotion/core';

const globalStyles = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    height: 100%;
    font-size: 89%;
  }

  body {
    height: 100%;
    font-family: 'PT Sans', 'Lato', -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    background-color: #fff;
  }

  html {
    scroll-behavior: smooth;
  }

  html,
  body,
  h1,
  h2,
  h3,
  h4,
  a,
  p,
  ul,
  ol,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    padding: 0;
    margin: 0;
  }

  ul,
  ol {
    list-style: none;
  }

  p,
  span,
  a,
  strong {
    font-family: 'Lato', sans-serif;
  }

  h1 {
    font-size: 72px;
    line-height: 120%;
    font-weight: bold;
  }

  h2 {
    font-size: 56px;
  }

  h3 {
    font-size: 2.5rem;
  }

  h4 {
    font-size: 2rem;
  }

  body {
    font-family: var(--font-family);
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    width: 8px;
    transition: 0.3s ease;
  }

  ::-webkit-scrollbar-track {
    background: var(--shadow-color);
  }

  ::-webkit-scrollbar-thumb {
    background: #1c1733;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  h1,
  h2,
  h3,
  h4,
  li {
    font-family: 'PT Sans', sans-serif;
  }

  img {
    max-width: 100%;
    display: block;
  }

  button:focus {
    outline: none;
    box-shadow: 0 0 5px #000;
  }
  #__next {
    height: 100%;
  }

  input,
  button,
  textarea,
  select {
    font: 'PT Sans', sans-serif;
  }
  .Toastify__toast--success {
    background: #04c686;
  }
  .Toastify__toast--error {
    background: #d64541;
  }

  .slick-slide {
    pointer-events: none;
  }

  .slick-active {
    pointer-events: auto;
  }

  #carouselB2C {
    .slick-active {
      opacity: 1 !important;
    }
    .slick-slide {
      opacity: 0.5;
    }
    @media only screen and (max-width: 650px) {
      .slick-list {
        padding-left: 0px !important;
      }
    }
  }
  #input {
    .label {
      position: absolute;
      top: 50%;
      left: 21px;
      transform: translateY(-50%);
      line-height: 20px;
      color: #80868b;
      transition: all 0.2s ease-in-out;
      width: unset;
      background-color: #fff;
      width: auto !important;
    }

    .label-old {
      position: absolute;
      top: 2px;
      width: unset;
      left: 15px;
      transform: translateY(0%);
      line-height: 20px;
      color: #1a73e8;
      padding: 0 5px;
      color: #6e2bed;
      transition: all 0.2s ease-in-out;
      font-size: 12.5px !important;
      background-color: transparent !important;
    }
    input:focus + .label,
    select + .label,
    input:focus + .label-old,
    select + .label-old {
      position: absolute;
      top: 0;
      left: 15px;
      transform: translateY(0%);
      line-height: 20px;
      color: #1a73e8;
      padding: 0 5px;
      color: #6e2bed;
      font-size: 12px;
      background-color: transparent !important;
    }
    input:focus + div + .label,
    select + div + .label,
    input:focus + div + .label-old,
    select + div + .label-old {
      position: absolute;
      top: 0;
      left: 15px;
      transform: translateY(0%);
      line-height: 20px;
      color: #1a73e8;
      padding: 0 5px;
      color: #6e2bed;
      font-size: 12px;
      background-color: transparent !important;
    }
    .error + input {
      border: 1px solid #e52d70 !important;
    }
    .error + input + label {
      color: #e52d70 !important;
    }
  }
  #formB2C {
    .input-content {
      position: relative;
    }

    input {
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      outline: none;
      line-height: 20px;
      padding-left: 25px;
    }
    input:focus {
      border-color: #6e2bed;
    }
    input.active {
      border-color: #6e2bed;
    }

    .label {
      position: absolute;
      top: 50%;
      left: 23px;
      transform: translateY(-50%);
      line-height: 20px;
      color: #80868b;
      transition: all 0.2s ease-in-out;
      width: unset;
    }

    .label-old {
      position: absolute;
      top: 0;
      width: unset;
      left: 15px;
      background: #fff;
      transform: translateY(-50%) scale(0.9);
      line-height: 20px;
      color: #1a73e8;
      padding: 0 5px;
      color: #6e2bed;
    }
    input:focus + .label {
      position: absolute;
      top: 0;
      left: 15px;
      background: #fff;
      transform: translateY(-50%) scale(0.9);
      line-height: 20px;
      color: #1a73e8;
      padding: 0 5px;
      color: #6e2bed;
    }
  }
  .fadeOut {
    opacity: 0;
    width: 0;
    height: 0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
    position: absolute;
    top: -50px;
    left: 0;
    border: 1px solid;
    z-index: 999;
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
  }
  .fadeIn {
    opacity: 1;
    height: 100px;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
    position: absolute;
    top: -50px;
    left: 0;
    border: 1px solid;
    width: 50%;
    z-index: 999;
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
  }
`;

export default globalStyles;
