import * as React from 'react';
import { Global } from '@emotion/core';
import { ThemeProvider } from 'theme-ui';
import theme from './theme';
import globalStyles from './global';

type ProviderProps = {
  children?: React.ReactNode;
};

export default function Provider(props: ProviderProps) {
  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      {props.children}
    </ThemeProvider>
  );
}
