import React, { useEffect, useState } from 'react';
import ThemeProvider from '../styles/provider';
import Head from 'next/head';
import { AppPropsType } from 'next/dist/next-server/lib/utils';
import 'react-credit-cards/es/styles-compiled.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Text, Button } from 'theme-ui';
import { GTMPageView, shouldRemoveGTM } from '../utils/gtm';
import { Router, useRouter } from 'next/router';
import { init as initApm } from '@elastic/apm-rum'

const App = ({ Component, pageProps }: AppPropsType) => {
  const [showCookieMsg, setShowCookieMsg] = useState(false);
  const [shouldUseGtag, setShouldUseGtag] = useState(false);
  const router = useRouter();
  const { asPath } = router;

  const hideCookieMsg = () => {
    localStorage.setItem('@flix:cookies', 'true');
    setShowCookieMsg(false);
  };

  const handleRouteChange = (url: string) => {
    GTMPageView(url);
  };

  useEffect(() => {
    // const handleRouteChange = (url: string) => 1;
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    // i know this is ugly but should be removed soon :/
    if (window) {
      const splitPath = window.location.pathname.split('/');
      const partnerId = splitPath[splitPath.length - 1];
      if (!shouldRemoveGTM(partnerId)) {
        if (
          window.location.hostname !== 'localhost' &&
          window.location.hostname !== 'site.dev.flix.com.vc' &&
          window.location.hostname !== 'flix.codes'
        ) {
          setShouldUseGtag(true);
          initApm({
            serviceName: 'site-front',
            serverUrl: 'https://apm-server.prod.flix.com.vc',
            serviceVersion: '',
            environment: 'production'
          })
        } else {
          initApm({
            serviceName: 'site-front',
            serverUrl: 'https://apm-server.prod.flix.com.vc',
            serviceVersion: '',
            environment: 'development'
          })
        }
      }
    }
  }, []);

  useEffect(() => {
    const acceptCookies = localStorage.getItem('@flix:cookies');

    if (!acceptCookies) {
      setShowCookieMsg(true);
    }
  }, [setShowCookieMsg]);

  return (
    <ThemeProvider>
      <Head>
        {shouldUseGtag ? (
          <script
            id="gtm-tag1"
            dangerouslySetInnerHTML={{
              // <!-- Google Tag Manager -->
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-59X8SNT');`,
              // <!-- End Google Tag Manager -->
            }}
          />
        ) : (
          <script
            id="gtm-tag1"
            dangerouslySetInnerHTML={{
              // <!-- Google Tag Manager -->
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-59XZLDM');`,
              // <!-- End Google Tag Manager -->
            }}
          />
        )}
      </Head>
      {shouldUseGtag ? (
        <noscript
          id="gtm-tag2"
          dangerouslySetInnerHTML={{
            // Google Tag Manager (noscript)
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-59X8SNT"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            // End Google Tag Manager (noscript)
          }}
        />
      ) : (
        <noscript
          id="gtm-tag2"
          dangerouslySetInnerHTML={{
            // Google Tag Manager (noscript)
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-59XZLDM"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            // End Google Tag Manager (noscript)
          }}
        />
      )}
      <Component sx={{ height: '100%' }} {...pageProps} />
      {showCookieMsg && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '10px',
            backgroundColor: 'primary',
            color: 'white',
            padding: '10px 20px',
            zIndex: 4,
            fontFamily: 'Lato',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}>
          <Text>
            A Flix utiliza cookies e outras tecnologias semelhantes para
            melhorar a sua experiência, de acordo com a nossa Política de
            Privacidade e, ao continuar navegando, você concorda com estas
            condições.
          </Text>
          <Button
            onClick={() => hideCookieMsg()}
            sx={{
              marginLeft: '30px',
              minWidth: '70px',
            }}
            variant="buttons.secondary"
            data-cy="close-notification-button">
            Ok
          </Button>
        </Box>
      )}
    </ThemeProvider>
  );
};

export default App;
