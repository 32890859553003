import { tailwind } from '@theme-ui/presets';

export default {
  ...tailwind,
  styles: {
    ...tailwind.styles,
    a: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  colors: {
    primary: '#612FE9',
    secondary: '#12BDE5',
    black2business: '#1C1733',
    primary2business: '#04C686',
    dark2business: '#312C46',
    white: '#FFF',
    lightGray: '#F5F5FB',
    mediumGray: '#9698B4',
    darkGray: '#50515F',
    successPrimary: '#04C686',
    successSecondary: '#3AD78C',
    error: '#d64541',
    business: '#05AA74',
    alert: '#FF4D00',
  },
  fonts: {
    ...tailwind.fonts,
  },
  sizes: {
    ...tailwind.sizes,
  },
  links: {
    nav: {
      textTransform: 'uppercase',
      fontSize: 3,
      color: 'white',
      '&::after': {
        content: '""',
        display: 'block',
        height: '2px',
        width: 12,
        backgroundColor: '#230C33',
        marginTop: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      '&:hover': {
        cursor: 'pointer',
        color: 'white',
        '&::after': {
          backgroundColor: '#16DB93',
          transition: '0.3s',
        },
      },
    },
  },
  buttons: {
    primary: {
      letterSpacing: '0.5px',
      height: '56px',
      color: '#FFF',
      textAlign: 'center',
      fontFamily: 'Lato',
      fontWeight: 'bold',
      backgroundColor: 'primary',
      borderRadius: '8px',
      transition: '.3s ease',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '0px 16px 32px -8px rgba(97, 47, 233, 0.4)',
      },
    },
    secondary: {
      transition: '.3s ease',
      letterSpacing: '0.5px',
      height: '56px',
      color: 'primary',
      textAlign: 'center',
      fontFamily: 'Lato',
      fontWeight: 'bold',
      backgroundColor: '#fff',
      borderRadius: '8px',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '0px 16px 32px -8px rgba(97, 47, 233, 0.4)',
      },
      border: '2px solid #612FE8',
    },
    tertiary: {
      fontWeight: 'bold',
      borderRadius: '8px',
      width: '280px',
      minWidth: '280px',
      marginBottom: '1.5rem',
      height: '64px',
      fontSize: '18px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fff',
      color: '#9698B4',
    },
    clean: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
      backgroundColor: 'transparent',
      '&:focus': { boxShadow: 'none' },
    },
    menuButton: {
      width: '100%',
      display: 'flex',
      backgroundColor: '#fff',
      color: 'black2business',
      justifyContent: 'flex-start',
      alignItems: 'center',
      transition: '.3s ease',
      textAlign: 'start',
      paddingLeft: '3rem',
      '&:focus': { outline: 'none', boxShadow: 'none' },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'lightGray',
        color: 'primary',
      },
      fontWeight: '700',
    },
  },
  labels: {
    default: {
      fontWeight: 'bold',
      fontSize: '12px',
      fontFamily: 'Lato',
    },
  },
  inputs: {
    default: {
      borderRadius: '8px',
      border: '1px solid #9698B4',
      height: '48px',
      paddingLeft: '15px',
      fontFamily: 'Lato',
      fontSize: '16px',
      '&:focus': {
        outline: 'none',
      },
    },
    cleanDisabled: {
      fontFamily: 'Lato',
      border: 'none',
      borderBottom: '1px solid lightGray',
      borderRadius: '0',
      color: 'darkGray',
      fontSize: '16px',
      height: '35px',
      marginBottom: '2rem',
      '&:focus': {
        outline: 'none',
      },
    },
    error: {
      borderRadius: '8px',
      height: '48px',
      paddingLeft: '15px',
      fontFamily: 'Lato',
      fontSize: '16px',
      boxShadow: '0 0 5px #E52D70',
      border: '1px solid #E52D70',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  textarea: {
    default: {
      borderRadius: '8px',
      border: '1px solid #9698B4',
      height: '180px',
      paddingTop: '10px',
      paddingLeft: '15px',
      fontFamily: 'Lato',
      fontSize: '16px',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  spinner: {
    color: '#612FE8',
  },
  spinnerWhite: {
    color: '#fff',
  },
  list__item: {
    dot__colorful: {
      listStyle: 'none',
      color: 'darkGray',
      '::before': {
        content: '"• "',
        color: 'primary',
        fontWeight: 'bold',
        marginLeft: '-1rem',
        paddingRight: '0.3rem',
      },
    },
  },
};
